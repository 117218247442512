export const isBuilderDisabled =
  process.env.NEXT_PUBLIC_DISABLE_BUILDER === 'true';
export const isColorModeOff =
  process.env.NEXT_PUBLIC_HIDE_COLOR_MODE === 'true';
export const isActiveFlowHealthOff =
  process.env.NEXT_PUBLIC_HIDE_ACTIVE_FLOW_HEALTH === 'true';
export const isMarketplaceOff =
  process.env.NEXT_PUBLIC_HIDE_MARKETPLACE === 'true';
export const isPremiumOff = process.env.NEXT_PUBLIC_HIDE_PREMIUM === 'true';
export const isOnBoardingOff =
  process.env.NEXT_PUBLIC_HIDE_ON_BOARDING === 'true';
export const isAlertsOff = process.env.NEXT_PUBLIC_HIDE_ALERTS === 'true';
export const isDappConnectOff =
  process.env.NEXT_PUBLIC_HIDE_DAPP_CONNECT === 'true';
export const hideExportingToMarketplace =
  process.env.NEXT_PUBLIC_HIDE_EXPORTING_TO_MARKETPLACE === 'true';
export const serviceUrl = process.env.NEXT_PUBLIC_SERVICE_URL;
export const serviceKey = process.env.NEXT_PUBLIC_SERVICE_KEY;
export const serviceSecret = process.env.NEXT_PUBLIC_SERVICE_SECRET;
export const showAppDrafts = process.env.NEXT_PUBLIC_SHOW_APP_DRAFTS === 'true';
export const noSpalshScreen =
  process.env.NEXT_PUBLIC_NO_SPLASH_SCREEN === 'true';

export const supportedNetworkIds =
  process.env.NEXT_PUBLIC_SUPPORTED_NETWORK_IDS;

export const showMenuInMarketplace =
  process.env.NEXT_PUBLIC_SHOW_MENU_IN_MARKETPLACE === 'true';

export const showSimulattionInApps =
  process.env.NEXT_PUBLIC_SHOW_SIMULATION_IN_APPS === 'true';

export const INIT_COLOR_MODE = process.env.NEXT_PUBLIC_INIT_COLOR_MODE;
export const CHATBASE_API_KEY = process.env.NEXT_PUBLIC_CHATBASE_API_KEY;
export const CHATBASE_ID = process.env.NEXT_PUBLIC_CHATBASE_ID;
export const COOKIEBOT_ID = process.env.NEXT_PUBLIC_COOKIEBOT_ID;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const HOTJAR_SITE_ID = process.env.NEXT_PUBLIC_HOTJAR_SITE_ID;
export const SHOW_AI_ASSITANT =
  process.env.NEXT_PUBLIC_SHOW_AI_ASSISTANT === 'true';
